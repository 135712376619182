import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { rgba } from "polished"
import React, { useEffect } from "react"
import { Col, Container, Row, useScreenClass } from "react-grid-system"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import ContactForm from "../components/forms/contactForm"
import Layout from "../components/layout"
import Main from "../components/main"
import SEO from "../components/seo"
import Video from "../components/video"
import CaseStudiesGrid from "../components/work/caseStudiesGrid"

const Content = (props) => {
  let content
  if (props.content === null) content = ``
  if (props.content.shortText) {
    content = (
      <span
        dangerouslySetInnerHTML={{ __html: props.content.shortText }}
        className={props.className}
        {...props}
      />
    )
  }
  if (props.content.richText && props.content.richText) {
    content = (
      <div className={props.className} {...props}>
        {documentToReactComponents(JSON.parse(props.content.richText.raw))}
      </div>
    )
  }
  return content
}

const StyledMain = styled(Main)`
  margin-top: 0;
  padding-top: 0;
`

const SectionHero = styled.section`
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  min-height: 100vh;
  position: relative;
  padding-bottom: 64px;
  padding-top: 64px;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobile}) {
    padding-bottom: 2.3rem;
    padding-top: 2.3rem;
    min-height: 490px;
  }
`

const HeroImage = styled(GatsbyImage)`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`

const Headline = styled.h1`
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.serif};
  font-size: 3rem;
  line-height: 1;
  margin-top: 5em;
  margin-bottom: 1rem;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobile}) {
    margin-top: 1em;
    font-size: 1.78rem;
  }
`

const HeroText = styled.div`
  margin-bottom: 1rem;
  margin-right: 3rem;
`

const HeroCta = styled.a`
  box-sizing: border-box;
  max-width: 260px;
  width: 260px;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobile}) {
    max-width: 100%;
    width: 100%;
  }
`

const Section = styled.section`
  position: relative;
  padding-bottom: 64px;
  padding-top: 64px;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobile}) {
    padding-bottom: 2.3rem;
    padding-top: 2.3rem;
  }
`

const SectionAnchor = styled.a`
  height: 0;
  left: 0;
  position: absolute;
  top: -100px;
  width: 0;
  visibility: hidden;
`

const SectionGrey = styled(Section)`
  background-color: ${(props) => rgba(props.theme.colors.gray22, 0.08)};
`
const SectionDarker = styled(Section)`
  background-color: ${(props) => props.theme.colors.darker};
`

const H2 = styled.h2``

const Card = styled.div`
  border: 1px solid ${(props) => props.theme.colors.gray70};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.4rem 1.8rem;
`

const CardTitle = styled.h3`
  border-bottom: 1px solid ${(props) => props.theme.colors.highlight};
  display: block;
  font-size: 1.55rem;
  line-height: 1.290322580645161;
  margin-bottom: 1em;
  margin-right: auto;
  padding-bottom: 0.4193548387096774em;
`

const OverviewText = styled.div``

const LandingPage = (props) => {
  const {
    name,
    seoTitle,
    seoDescription,
    openGraphImage,
    heroImage,
    headline1,
    overview1,
    ctaText,
    headline2,
    overview2,
    image1,
    headline3,
    overview3,
    imagesArea1,
    headline4,
    overview4,
    imagesArea2,
    headline5,
    caseStudies,
    headline6,
    image2,
    ctaHeadline,
  } = props.data.contentfulLandingPage
  const slug = props.pageContext.slug

  const screenClass = useScreenClass()
  const [ref, inView, entry] = useInView({
    rootMargin: "0px 0px -99% 0px",
  })

  useEffect(() => {
    if (inView) {
      document.body.classList.add("is-intersecting__true")
    } else {
      document.body.classList.remove("is-intersecting__true")
    }
  }, [inView])

  return (
    <>
      <SEO
        title={seoTitle ? seoTitle : ``}
        description={
          seoDescription && seoDescription.seoDescription
            ? seoDescription.seoDescription
            : ``
        }
        pageUrl={props?.location?.href}
        image={openGraphImage?.localFile?.publicURL}
      />
      <Layout>
        <StyledMain>
          <SectionHero ref={ref} id={`landing__hero`}>
            <HeroImage
              image={heroImage.localFile.childImageSharp.gatsbyImageData}
              style={{ position: `absolute` }}
            />
            <Container
              style={{
                paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
                paddingRight: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
              }}
            >
              <Row align="center">
                <Col lg={8} offset={{ lg: 2 }}>
                  <Headline
                    dangerouslySetInnerHTML={{ __html: headline1 }}
                    className={`headline`}
                  />
                  <HeroText>{overview1}</HeroText>
                </Col>
              </Row>
              <Row>
                <Col lg={6} offset={{ lg: 2 }}>
                  <HeroCta href="#contact" className={`button button-white`}>
                    {ctaText}
                  </HeroCta>
                </Col>
              </Row>
            </Container>
            <p>{inView.toString()}</p>
          </SectionHero>

          <Section>
            <Container
              style={{
                paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
                paddingRight: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
              }}
            >
              <Row>
                <Col lg={8} offset={{ lg: 2 }}>
                  <H2 className={`headline`}>
                    <span dangerouslySetInnerHTML={{ __html: headline2 }} />
                  </H2>
                  <Row>
                    <Col
                      style={{
                        paddingBottom: `1rem`,
                      }}
                    >
                      {documentToReactComponents(JSON.parse(overview2.raw))}
                    </Col>
                  </Row>
                  {image1 && (
                    <Row>
                      <Col lg={10} offset={{ lg: 1 }}>
                        <GatsbyImage
                          image={
                            image1.localFile && image1.localFile.childImageSharp
                              ? image1.localFile.childImageSharp.gatsbyImageData
                              : image1.fluid
                          }
                        />
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Container>
          </Section>

          {headline3 ||
          (overview3 && overview3.raw) ||
          headline4 ||
          (overview4 && overview4.raw) ||
          Array.isArray(imagesArea1) ||
          Array.isArray(imagesArea2) ? (
            <SectionGrey>
              <Container
                style={{
                  paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                    ? "3rem"
                    : "1.5625rem",
                  paddingRight: ["md", "lg", "xl"].includes(screenClass)
                    ? "3rem"
                    : "1.5625rem",
                }}
              >
                <Row>
                  <Col lg={8} offset={{ lg: 2 }}>
                    {headline3 && (
                      <H2 className={`headline`}>
                        <span dangerouslySetInnerHTML={{ __html: headline3 }} />
                      </H2>
                    )}
                    <Row>
                      <Col
                        style={{
                          paddingBottom: `1rem`,
                        }}
                      >
                        {overview3 && overview3.raw && (
                          <OverviewText>
                            {documentToReactComponents(
                              JSON.parse(overview3.raw)
                            )}
                          </OverviewText>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>

              {Array.isArray(imagesArea1) && (
                <Container style={{ padding: 0, maxWidth: "100%" }}>
                  <Row nogutter>
                    {imagesArea1.map((image, index) => (
                      <Col key={index} md={12}>
                        {image.file.contentType.slice(
                          0,
                          image.file.contentType.indexOf("/")
                        ) === "image" && (
                          <GatsbyImage
                            image={
                              image.localFile && image.localFile.childImageSharp
                                ? image.localFile.childImageSharp
                                    .gatsbyImageData
                                : fluid
                            }
                          />
                        )}
                        {image.file.contentType.slice(
                          0,
                          image.file.contentType.indexOf("/")
                        ) === "video" && <Video {...image} key={index} />}
                      </Col>
                    ))}
                  </Row>
                </Container>
              )}

              <Container
                style={{
                  paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                    ? "3rem"
                    : "1.5625rem",
                  paddingRight: ["md", "lg", "xl"].includes(screenClass)
                    ? "3rem"
                    : "1.5625rem",
                }}
              >
                <Row>
                  <Col lg={8} offset={{ lg: 2 }}>
                    {headline4 && (
                      <H2 className={`headline`}>
                        <span dangerouslySetInnerHTML={{ __html: headline4 }} />
                      </H2>
                    )}
                    <Row>
                      <Col
                        style={{
                          paddingBottom: `1rem`,
                        }}
                      >
                        {overview4 && overview4.raw && (
                          <OverviewText>
                            {documentToReactComponents(
                              JSON.parse(overview4.raw)
                            )}
                          </OverviewText>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>

              {Array.isArray(imagesArea2) && (
                <Container style={{ padding: 0, maxWidth: "100%" }}>
                  <Row nogutter>
                    {imagesArea2.map((image, index) => (
                      <Col key={index} md={12}>
                        {image.file.contentType.slice(
                          0,
                          image.file.contentType.indexOf("/")
                        ) === "image" && (
                          <GatsbyImage
                            image={
                              image.localFile && image.localFile.childImageSharp
                                ? image.localFile.childImageSharp
                                    .gatsbyImageData
                                : fluid
                            }
                          />
                        )}
                        {image.file.contentType.slice(
                          0,
                          image.file.contentType.indexOf("/")
                        ) === "video" && <Video {...image} key={index} />}
                      </Col>
                    ))}
                  </Row>
                </Container>
              )}
            </SectionGrey>
          ) : null}

          {caseStudies && (
            <Section>
              <Container
                style={{
                  paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                    ? "3rem"
                    : "1.5625rem",
                  paddingRight: ["md", "lg", "xl"].includes(screenClass)
                    ? "3rem"
                    : "1.5625rem",
                }}
              >
                <Row>
                  <Col lg={8} offset={{ lg: 2 }}>
                    <H2
                      className={`headline`}
                      style={{
                        paddingBottom: `1rem`,
                      }}
                    >
                      <span dangerouslySetInnerHTML={{ __html: headline5 }} />
                    </H2>
                  </Col>
                </Row>
              </Container>
              <CaseStudiesGrid caseStudies={caseStudies} />
            </Section>
          )}

          {headline6 || image2 ? (
            <SectionDarker>
              <Container
                style={{
                  paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                    ? "3rem"
                    : "1.5625rem",
                  paddingRight: ["md", "lg", "xl"].includes(screenClass)
                    ? "3rem"
                    : "1.5625rem",
                }}
              >
                <Row>
                  <Col lg={8} offset={{ lg: 2 }}>
                    {headline6 && (
                      <H2 className={`headline white`}>
                        <span dangerouslySetInnerHTML={{ __html: headline6 }} />
                      </H2>
                    )}
                    <Row>
                      <Col>
                        {image2 && (
                          <GatsbyImage
                            image={
                              image2.localFile &&
                              image2.localFile.childImageSharp
                                ? image2.localFile.childImageSharp
                                    .gatsbyImageData
                                : image2.fluid
                            }
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </SectionDarker>
          ) : null}

          <Section>
            <SectionAnchor id="contact" />
            <Container
              style={{
                paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
                paddingRight: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
              }}
            >
              <Row align="center" justify="center">
                <Col lg={8}>
                  <H2
                    className={`headline`}
                    style={{
                      paddingBottom: `1rem`,
                    }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: ctaHeadline }} />
                  </H2>
                  <ContactForm />
                </Col>
              </Row>
            </Container>
          </Section>
        </StyledMain>
      </Layout>
    </>
  )
}

export const query = graphql`
  query LandingQuery($slug: String!) {
    contentfulLandingPage(slug: { eq: $slug }) {
      id
      slug
      name
      heroImage {
        localFile {
          ...workHero
        }
        fluid(maxWidth: 1920, quality: 95) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      headline1
      overview1
      ctaText
      headline2
      overview2 {
        raw
      }
      image1 {
        localFile {
          ...workImage
        }
        fluid(maxWidth: 1920, quality: 95) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      imagesArea1 {
        file {
          contentType
        }
        localFile {
          ...workImage
          publicURL
        }
        fluid(maxWidth: 1920, quality: 95) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      imagesArea2 {
        file {
          contentType
        }
        localFile {
          ...workImage
          publicURL
        }
        fluid(maxWidth: 1920, quality: 95) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      headline3
      overview3 {
        raw
      }
      headline4
      overview4 {
        raw
      }
      headline5
      caseStudies {
        id
        clientName
        previewText
        previewMedia {
          file {
            contentType
          }
          localFile {
            ...workPreview
            publicURL
          }
          fluid(maxWidth: 920, quality: 95) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        previewWeight
        category {
          name
          slug
        }
        slug
      }
      headline6
      image2 {
        localFile {
          ...workImage
        }
        fluid(maxWidth: 1920, quality: 95) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      ctaHeadline
      seoTitle
      seoDescription {
        seoDescription
      }
      openGraphImage {
        localFile {
          publicURL
        }
      }
    }
  }
`

export default LandingPage
