import React, { useState } from 'react'

const Video = (props) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false)
  const onVideoLoaded = () => {
    setIsVideoLoaded(true)
  }
  return (
    <div className={props.className}>
      <video
        muted="muted"
        autoPlay="autoplay"
        loop="loop"
        playsInline
        width="100%"
        onLoadedData={onVideoLoaded}
      >
        <source src={props.localFile.publicURL} type={props.file.contentType} />
      </video>
    </div>
  )
}

export default Video
